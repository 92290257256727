
.cost-centers-container {
  padding: 0px;
  margin-top: 0px;
  background-color: #f5f5f5; 
  display: flex;
  justify-content: center; 
}

.cost-centers-card {
  background: #ffffff !important;
  border-radius: 16px !important;
  max-width: 100%; 
  width: 100%; 

}

.cost-centers-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0px;
  margin-top: 0px;

}

.cost-centers-card {
  margin: 0 !important;
  border: none; 
}

.cost-centers-card .ant-card-body {
  padding: 18px 18px 18px 18px !important;
}

.cost-centers-card .ant-card-head {
  padding: 0 !important;
  margin: 0 !important; 
}
@media (max-width: 768px) {
  .cost-centers-container {
    padding: 0px;
  }

  .cost-centers-card .ant-card-body {
    padding: 8px 4px 4px 4px !important;
  }

  .cost-centers-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .cost-centers-card .ant-card-body {
    padding: 8px 3px 3px 3px !important;

  }
  .cost-centers-header {
    margin-bottom: 10px;
  }
}


.horizontal-form-costCenters {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  width: 100%;
  padding: 20px;
}

.form-group-costCenters {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-label-costCenters {
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "MontserratRegular";

}

.input-style-costCenters {
  padding: 10px;
  font-size: 16px;
  font-family: "MontserratRegular";

}

.icon-edit {
  color: #e57373; 
  transition: color 0.3s ease;
}

.icon-edit:hover {
  color: #d32f2f; 
}

.icon-delete {
  color: #f44336; 
  transition: color 0.3s ease;
}

.icon-delete:hover {
  color: #b71c1c; 
}
