
.transport-types-container {
  padding: 0px;
  margin-top: 0px;
  background-color: #f5f5f5; 
  display: flex;
  justify-content: center; 
}

.transport-types-card {
  background: #ffffff !important;
  border-radius: 16px !important;
  max-width: 100%; 
  width: 100%; 

}

.transport-types-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0px;
  margin-top: 0px;

}

.transport-types-card {
  margin: 0 !important;
  border: none; 
}

.transport-types-card .ant-card-body {
  padding: 18px 18px 18px 18px !important;
}

.transport-types-card .ant-card-head {
  padding: 0 !important;
  margin: 0 !important; 
}

@media (max-width: 768px) {
  .transport-types-container {
    padding: 0px;
  }

  .transport-types-card .ant-card-body {
    padding: 8px 4px 4px 4px !important;
  }

  .transport-types-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .transport-types-card .ant-card-body {
    padding: 8px 3px 3px 3px !important;

  }
  .transport-types-header {
    margin-bottom: 10px;
  }
}

.horizontal-form-transportTypes {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  width: 100%;
  padding: 20px;
}

.form-group-transportTypes {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-label-transportTypes {
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "MontserratRegular";
}

.input-style-transportTypes {
  padding: 10px;
  font-size: 16px;
  font-family: "MontserratRegular";
}
