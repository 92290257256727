:root {
    --main-color: #041370;
    --secondary-color: #5955d9;
    --third-color: #f25e6b;
    --gris-obscuro: #808080;
    --normal-color: #222831;
    --background-color: #ffffff;
  --icon-size: 40px;
  --icon-font-size: 22px;
  --icon-bg: #1890ff;
  --icon-color: #fff;
  --icon-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  --icon-hover-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  --icon-active-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  --edit-icon-color: #e57373;
  --edit-icon-hover-color:  #1890ff;
  --delete-icon-color: #f44336;
  --delete-icon-hover-color: #1890ff;
  --modal-title-color: #1d1d1f;
  --modal-content-color: #595959;
  --modal-border-radius: 12px;
  --modal-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  --ok-button-bg: #d9363e;
  --ok-button-border: #d9363e;
  --ok-button-hover-bg: #a82222;
  --ok-button-hover-border: #a82222;
  --cancel-button-bg: #f5f5f5;
  --cancel-button-hover-bg: #e6e6e6;
  --button-general-color: #e57373;
  --button-general-hover-color: #1890ff;
  --button-general-border-color: #e57373; 
  --button-general-border-hover-color: #1890ff;
}
  
  
  .title {
    font-family: "MontserratBold";
    font-weight: 700;
    font-size: 32px;
    color: var(--main-color);
    padding-top: 0;
    margin: 0;
    line-height: 1;
  }
@media (max-width: 768px) {
    .title {
      font-size: 24px; 
      line-height: 1.2; 
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 18px; 
      line-height: 1.3; 
      text-align: center; 
    }
  }
  .subtitle {
    font-weight: 800;
    font-size: 24px;
    color: var(--gris-obscuro);
  }
  
  .textbody {
    font-weight: 600;
    font-size: 12px;
    color: var(--normal-color);
  }
  
  .tag {
    padding: 0.1em 0.5em;
    color: var(--main-color);
    font-weight: 800;
    border-radius: 20px;
    box-shadow: 0px 3px 5px 0px rgba(181, 181, 181, 0.5);
  }
 
 .add-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: var(--icon-size);
   height: var(--icon-size);
   border-radius: 50%;
   background-color: var(--icon-bg);
   color: var(--icon-color);
   font-size: var(--icon-font-size);
   border: none;
   cursor: pointer;
   transition: transform 0.3s ease, box-shadow 0.3s ease;
   box-shadow: var(--icon-shadow);
 }
 
 .add-icon:hover {
   transform: scale(1.1);
   box-shadow: var(--icon-hover-shadow);
 }
 
 .add-icon:active {
   transform: scale(0.95);
   box-shadow: var(--icon-active-shadow);
 }
 
 @media (max-width: 768px) {
   .add-icon {
     width: 36px;
     height: 36px;
     font-size: 20px;
   }
 }
 .icon-edit {
  color: var(--edit-icon-color) !important;
  transition: color 0.3s ease;
}

.icon-edit:hover {
  color: var(--edit-icon-hover-color) !important;
}
.icon-delete {
  color: var(--delete-icon-color);
  transition: color 0.3s ease;
}

.icon-delete:hover {
  color: var(--delete-icon-hover-color);
}
.button-general {
  color: var(--button-general-color);
  border: 1px solid var(--button-general-border-color); 
  border-radius: 8px; 
  background: transparent;
  padding: 6px 12px; 
  transition: all 0.3s ease;
  cursor: pointer;
}

.button-general:hover {
  color: var(--button-general-hover-color);
  border-color: var(--button-general-border-hover-color); 
}
.input-counter {
  font-size: 12px;
  color: #888;
  margin-right: 8px;
  transition: color 0.3s;
}

.input-counter.counter-limit {
  color: #ff4d4f; 
}

.input-icon {
  font-size: 16px;
  color: #1890ff;
  margin-left: 8px;
  cursor: pointer;
}
