.form-container {
  font-family: "MontserratRegular";
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-form-item-label>label {
  font-style: normal;
  font-weight: 500px;
  font-size: 14px;
  line-height: 17px;
  color: #041370;
}

.ant-input {}

.item-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
